export const viewport = () => {
  const viewport = document.querySelector('meta[name="viewport"]');
  
  function switchViewport() {
    const value =
      window.outerWidth > 375
        ? 'width=device-width,initial-scale=1'
        : 'width=375';
    if (viewport.getAttribute('content') !== value) {
      viewport.setAttribute('content', value);
    }
  }

  // ウィンドウのリサイズ時とページの読み込み時にswitchViewportを実行
  window.addEventListener('resize', switchViewport);
  window.addEventListener('load', switchViewport);

  // 初回実行
  switchViewport();
};
