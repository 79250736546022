export const hamburger = () => {
    $(".js-hamburger").click(function () {//ボタンがクリックされたら
        $(this).toggleClass('active');//ボタン自身に activeクラスを付与し
        $(".js-nav").toggleClass('panelactive');//ナビゲーションにpanelactiveクラスを付与
    });
    
    $(".js-nav a").click(function () {//ナビゲーションのリンクがクリックされたら
        $(".js-hamburger").removeClass('active');//ボタンの activeクラスを除去し
        $(".js-nav").removeClass('panelactive');//ナビゲーションのpanelactiveクラスも除去
    });
}
